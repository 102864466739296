import { useRef, useState } from "react";
import { pushDataLayer } from "~lib/analytics/datalayer";
import { cx } from "~lib/utils";
import { Input } from "~ui/Input";
import { fetchWithCSRF } from "~core/fetch";

import styles from "./NewsletterSignup.module.css";

const assets = import.meta.glob("./*.jpg", {
  as: "url",
  eager: true,
});

const IconCheckSuccess = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" className={styles.icon}>
    <path
      d="M6.75 12.25L1.75 7.25L0.25 8.75L6.75 15.25L18.25 3.75L16.75 2.25L6.75 12.25Z"
      fill="currentColor"
    />
  </svg>
);

export default function NewsletterSignup() {
  const formRef = useRef(null);
  const [submissionStatus, setSubmissionStatus] = useState("idle");
  const [email, setEmail] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const submitForm = async () => {
    const formData = new FormData(formRef.current);
    const body = new URLSearchParams(formData);

    const res = fetchWithCSRF(`/platform/api/v2/emailpreference/subscribe/`, {
      method: "POST",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    setEmail(formData.get("email"));
    setSubmissionStatus("loading");

    try {
      await res;
      setSubmissionStatus("success");

      /* Do not use "newsletter-sign-up" DL event for signups. */
      pushDataLayer({
        event: "email-obtained-newsletter-signup",
        user: {
          newsletters: "today_in_science",
        },
      });
    } catch (error) {
      setSubmissionStatus("error");
    }
  };

  const desktopImg = assets[`./newsletterSignUpArt.jpg`];

  return (
    <section className={styles.newsletterSignup}>
      <form
        className={cx(styles.newsletterSignupForm, styles["status-" + submissionStatus])}
        onSubmit={handleFormSubmit}
        ref={formRef}
      >
        <h2 className={styles.newsletterSignupMainText}>Get Our Daily Newsletter</h2>
        <Input
          className={cx(styles.input, styles.newsletterSignupEmailInput)}
          type="email"
          name="email"
          placeholder="Enter your email"
          required
        />
        <div className={styles.privacyCheckboxContainer}>
          <Input
            className={cx(styles.checkbox, styles.privacyCheckbox)}
            type="checkbox"
            id="privacy-policy"
            required
          />
          <label className={styles.newsletterSignupPrivacyLabel} htmlFor="privacy-policy">
            I agree my information will be processed in accordance with the Scientific American and
            Springer Nature Limited <a href="/page/privacy-policy/">Privacy Policy</a>.
          </label>
        </div>

        {/* Newsletter list */}
        <input
          type="checkbox"
          name="lists"
          value="today_in_science"
          defaultChecked="checked"
          hidden
        />

        {/* Submit */}
        <button
          disabled={submissionStatus === "loading" || submissionStatus === "success"}
          className={styles.newsletterSignupBtn}
          type="submit"
        >
          Sign Up
        </button>
      </form>

      {/* Visually hidden until successful submission */}
      <div className={cx(styles.message, styles["status-" + submissionStatus])}>
        <p>Thank you for signing up!</p>
        <div className={styles.inputContainer}>
          {/* Mock input */}
          <div className={cx("form-input", styles.input)}>
            <span>{email || "\u00A0" /* that's an &nbsp; */}</span>
            <IconCheckSuccess />
          </div>
        </div>
        <p>
          <b>
            <a href="/newsletters/">Check out our other newsletters</a>
          </b>
        </p>
      </div>

      <div className={styles.newsletterSignupImg}>
        <img src={desktopImg} role="presentation" alt="" loading="lazy" width={356} height={249} />
      </div>
    </section>
  );
}
